<template>
  <div style="min-width: 1000px;">
    <nav-comp/>
    <el-scrollbar class="main-page-container">
      <router-view style="min-width: 1000px;"/>
      <footer-comp/>
    </el-scrollbar>
  </div>
</template>

<script>
import NavComp from '@/components/NavComp'
import FooterComp from '@/components/FooterComp'

export default {
  name: 'AppLayoutPc',
  components: { NavComp, FooterComp }
}
</script>

<style scoped>

.main-page-container {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.main-page-container >>> .el-scrollbar__wrap {
  overflow-x: hidden;
}

</style>
