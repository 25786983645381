<template>
  <div>
    <nav-comp ref="nav"/>
    <div class="main-page-container" @click="$refs.nav.closeMbNavLinks()">
      <router-view/>
      <footer-comp/>
    </div>
  </div>
</template>

<script>
import NavComp from '@/components/NavComp'
import FooterComp from '@/components/FooterComp'

export default {
  name: 'AppLayoutPc',
  components: { NavComp, FooterComp }
}
</script>

<style scoped>

.main-page-container {
  height: calc(100vh - 60px);
  overflow-y: scroll;
}

</style>
