<template>
  <div>
    <div v-if="$store.getters.isOnline" class="user-container">
      <div @click="toMyCourse" class="book-icon"/>
      <div @click="toMyCourse" style="width: 65px; padding: 0 10px; border-right: 1px solid #d3d3d3; color: #333333;">我的课程</div>
      <div @click="toUserInfo" style="white-space: nowrap; margin-left: 10px; color: #333333;" v-if="$pc">{{ $store.state.auth.nickName }}</div>
      <div @click="toUserInfo" class="user-icon" style="margin: 0 10px;"/>
    </div>
    <div v-else class="register-login-container">
      <div class="login-button" @click="login">登录</div>
      <div class="register-button" @click="login">免费注册</div>
    </div>

    <el-dialog :visible.sync="loginVisible" append-to-body width="400px" :title="loginDialogText" :close-on-click-modal="false">
      <el-form label-width="55px">
        <el-tabs v-model="loginType">
          <el-tab-pane label="短信登录" name="1">
            <div style="margin-top: 10px;">
              <el-form-item label="手机号">
                <el-input v-model="loginData.phone"></el-input>
              </el-form-item>
              <el-form-item label="验证码">
                <el-input v-model="loginData.code" style="width: 50%;"></el-input>
                <el-button style="width: calc(50% - 5px); margin-left: 5px;" :disabled="codeCd > 0" @click="sendCode(false)">发送验证码{{ codeCd < 0 ? '' : `（${codeCd}）` }}</el-button>
              </el-form-item>
            </div>
          </el-tab-pane>
          <el-tab-pane label="密码登录" name="2">
            <div style="margin-top: 10px;">
              <el-form-item label="手机号">
                <el-input v-model="loginData.phone"></el-input>
              </el-form-item>
              <el-form-item label="密码">
                <el-input v-model="loginData.password" type="password"></el-input>
              </el-form-item>
            </div>
          </el-tab-pane>
        </el-tabs>
        <el-form-item label-width="0">
          <el-button type="primary" style="width: 100%;" @click="doLogin" :disabled="disableLogin">{{ loginDialogText }}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="updatePasswordShow" append-to-body width="400px" title="修改密码" :close-on-click-modal="false">
      <el-form ref="updatePasswordForm" label-width="65px" :model="updatePasswordData" :rules="updatePasswordRules">
        <el-form-item label="手机号">
          {{ $store.state.auth.phone }}
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <el-input v-model="updatePasswordData.code" style="width: 50%;"></el-input>
          <el-button style="width: calc(50% - 5px); margin-left: 5px;" :disabled="codeCd > 0" @click="sendCode(true)">发送验证码{{ codeCd < 0 ? '' : `（${codeCd}）` }}</el-button>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input v-model="updatePasswordData.newPassword" type="password"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="updatePasswordShow = false">取 消</el-button>
        <el-button type="primary" @click="updatePasswordSubmit">确 定</el-button>
      </template>
    </el-dialog>
    <div class="phone-message-panel" v-show="!chatShow">
      <div>
        <el-popover
          placement="left"
          trigger="hover"
        >
          <template>
            <div style="user-select: all; font-size: 20px;">0552-3163050</div>
          </template>
          <template #reference>
            <div style="width: 50px; height: 55px; border-radius: 50px 50px 0 0"></div>
          </template>
        </el-popover>
      </div>
      <div style="width: 50px; height: 55px; border-radius: 0 0 50px 50px;" @click="openChat"></div>
    </div>
    <div :class="`chat-panel chat-panel-${$pc ? 'pc' : 'mobile'}`" v-show="chatShow">
      <div style="padding: 10px; border-bottom: 1px solid #eaeaea;">
        <span style="font-size: 16px; user-select: none;">在线客服</span>
        <span @click="chatShow = false" style="float: right; font-size: 20px; font-weight: bolder; cursor: pointer;">
          <el-icon class="el-icon-close"></el-icon>
        </span>
      </div>
      <div class="chat-panel-msg-container" style="padding: 5px; height: calc(100% - 225px); border-bottom: 1px solid #eaeaea;">
        <el-scrollbar style="height: 100%; width: 100%;" ref="chatMsgPanel">
          <div v-for="(m, i) in msgList" :key="`msg-${i}`" :class="`msg-container msg-container-${m.type}`">
            <span :class="`msg-item msg-item-${m.type}`">{{ m.msg }}</span>
          </div>
        </el-scrollbar>
      </div>
      <div style="padding: 5px; height: 165px;">
        <el-input ref="msgEditor" type="textarea" :rows="5" resize="none" v-model="msg" @keydown.enter.native="sendMsg" @keyup.enter.native="msg = ''" :disabled="!wsConnected"></el-input>
        <el-button type="primary" @click="sendMsg" style="margin-top: 5px; float: right;" :disabled="!wsConnected">发送</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import openApi from '@/api/open-api'

export default {
  name: 'UserComp',
  data () {
    return {
      loginVisible: false,
      loginData: {
        phone: '',
        type: 1,
        password: '',
        code: ''
      },
      updatePasswordShow: false,
      updatePasswordData: {
        code: '',
        newPassword: ''
      },
      updatePasswordRules: {
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 5, max: 20, message: '密码长度必须介于 5 和 20 之间', trigger: 'blur' }
        ]
      },
      passwordUpdating: false,
      codeCd: -1,
      timer: undefined,
      disableLogin: false,
      chatShow: false,
      msg: '',
      ws: undefined,
      msgList: [], // type: info/customer/user; msg; time;
      wsHeartTimer: undefined,
      wsConnected: false
    }
  },
  computed: {
    loginDialogText () {
      return this.loginData.type === 1 ? '登录 / 注册' : '登录'
    },
    loginType: {
      get () {
        return this.loginData.type.toString()
      },
      set (v) {
        this.loginData.type = parseInt(v)
      }
    },
    chatId () {
      return this.$store.state.auth?.phone || this.$store.state.chatId
    }
  },
  created () {
    this.$eventBus.login = this.login
    this.$eventBus.updatePassword = this.updatePassword
    this.$eventBus.openSubmitApplyPage = this.openSubmitApplyPage
    this.$eventBus.openChat = this.openChat
  },
  methods: {
    login () {
      this.loginData = {
        phone: '',
        type: 1,
        password: '',
        code: ''
      }
      this.loginVisible = true
    },
    sendCode (isUpdatePassword) {
      if (this.codeCd > 0) return
      if (!isUpdatePassword && !this.loginData.phone) {
        this.msgError('请输入手机号')
        return
      }
      this.startCountdown()
      openApi.sendVerificationCode(isUpdatePassword ? this.$store.state.auth.phone : this.loginData.phone)
        .then(res => {
          if (res.code === 200) {
            this.msgSuccess('验证码发送成功')
          } else {
            this.msgError(res.msg)
          }
        })
    },
    doLogin () {
      if (!this.loginData.phone) {
        this.msgError('请输入手机号')
        return
      }
      if (this.loginData.type === 1 && !this.loginData.code) {
        this.msgError('请输入验证码')
        return
      }
      if (this.loginData.type === 2 && !this.loginData.password) {
        this.msgError('请输入密码')
        return
      }
      if (this.disableLogin) return
      this.disableLogin = true
      openApi.login(this.loginData)
        .then(res => {
          if (res.code === 200) {
            this.msgSuccess('登录成功')
            this.$store.commit('setAuth', res.data)
            this.loginVisible = false
          } else {
            this.msgError(res.msg)
          }
        })
        .finally(() => {
          this.disableLogin = false
        })
    },
    startCountdown () {
      this.codeCd = 60
      this.timer = setInterval(() => {
        this.codeCd = this.codeCd - 1
        if (this.codeCd < 0) {
          clearInterval(this.timer)
        }
      }, 1000)
    },
    toMyCourse () {
      if (this.$route.path === '/my-course') return
      this.$router.push({ path: '/my-course' })
    },
    toUserInfo () {
      if (this.$route.path === '/user-info') return
      this.$router.push({ path: '/user-info' })
    },
    updatePassword () {
      this.updatePasswordData = {
        code: '',
        newPassword: ''
      }
      this.updatePasswordShow = true
      this.$nextTick(() => {
        this.$refs.updatePasswordForm.clearValidate()
      })
    },
    updatePasswordSubmit () {
      if (this.passwordUpdating) return
      this.passwordUpdating = true
      openApi.updatePassword(this.updatePasswordData)
        .then(res => {
          if (res.code === 200) {
            this.msgSuccess('修改成功')
            this.updatePasswordShow = false
          } else {
            this.msgError(res.msg)
          }
        })
        .finally(() => { this.passwordUpdating = false })
    },
    openSubmitApplyPage (data) {
      if (!this.$store.getters.isOnline) {
        this.msgError('请登录/注册后继续操作')
        this.login()
        return
      }
      openApi.getApply()
        .then(res => {
          if (res.code === 401) {
            this.$store.commit('setAuth', {})
            this.msgError('登录过期，请重新登录')
            this.login()
            return
          }
          if (res.data?.length) {
            this.msgError('存在未完成课程，请完成课程后再报名')
            this.$router.push({ path: '/my-course' })
          } else {
            this.$router.push({
              name: 'apply',
              query: {
                schoolName: data.remark,
                schoolId: data.schoolId,
                majorName: data.name,
                majorId: data.majorId,
                level: data.level
              }
            })
          }
        })
    },
    openChat () {
      window.qimoChatClick()
      // if (!this.$pc) {
      //   this.msgError('手机端暂不支持在线客服')
      //   return
      // }
      // this.chatShow = true
      // if (!(this.ws && this.ws.readyState === WebSocket.OPEN)) {
      //   this.ws = new WebSocket(`${process.env.NODE_ENV === 'production' ? 'wss' : 'ws'}://${location.host}/webSocketServer/userChatServer`)
      //   this.ws.onopen = this.wsOpen
      //   this.ws.onmessage = this.wsMessage
      //   this.ws.onclose = this.wsClose
      // }
      // this.$nextTick(() => {
      //   this.$refs.msgEditor.focus()
      // })
    },
    sendMsg () {
      if (!this.wsConnected) return
      const msg = this.msg.trim().replaceAll('#', '')
      this.msg = ''
      this.$refs.msgEditor.focus()
      if (!msg.trim()) return
      this.msgList.push({
        type: 'user',
        msg,
        time: Date.now()
      })
      this.scrollToBottom()
      this.ws.send(`3##${msg}`)
    },
    wsOpen () {
      this.wsConnected = true
      this.ws.send(`1##${this.$store.state.restrict.location}##${this.chatId}`)
      this.wsHeartTimer = setInterval(() => {
        if (this.ws && this.ws.readyState === WebSocket.OPEN) {
          this.ws.send(`2##${this.chatId}`)
        } else {
          try { this.ws.close() } catch (e) {}
          this.ws = undefined
        }
      }, 5 * 60 * 1000)
    },
    wsMessage (e) {
      const data = e.data.split('##')
      switch (data[0]) {
        case '1':
          this.msgList.push({
            type: 'customer',
            msg: data[1],
            time: Date.now()
          })
          break
        case '2':
          this.msgList.push({
            type: 'info',
            msg: data[1],
            time: Date.now()
          })
          break
      }
      this.scrollToBottom()
    },
    wsClose () {
      this.wsConnected = false
      clearInterval(this.wsHeartTimer)
    },
    scrollToBottom () {
      this.$nextTick(() => {
        const wrap = this.$refs.chatMsgPanel.wrap
        wrap.scrollTop = wrap.scrollHeight
      })
    }
  }
}
</script>

<style scoped>

.register-login-container {
  width: 180px;
  display: flex;
  text-align: center;
  cursor: pointer;
}

.login-button {
  width: 80px;
  border-left: 1px solid #d3d3d3;
  margin: 18px 0;
  line-height: 24px;
  color: #333333;
}

.register-button {
  width: 100px;
  background-color: #0060ff;
  color: #ffffff;
  line-height: 60px;
}

/deep/ .el-dialog__body {
  padding: 5px 20px;
}

.book-icon {
  background: url("https://vip2.loli.net/2022/06/22/U2IGzZP9i3rRdbh.png") no-repeat;
  width: 21px;
  height: 16px;
}

.user-icon {
  background: url("https://vip2.loli.net/2022/06/22/9LCo2tEmWTKFDlU.png") no-repeat;
  width: 32px;
  height: 32px;
}

.user-container {
  min-width: 250px;
  height: 60px;
  display: flex;
  justify-content: right;
  align-items: center;
  cursor: pointer;
}

.phone-message-panel {
  position: absolute;
  right: 15px;
  top: 60vh;
  width: 50px;
  height: 111px;
  border-radius: 50px;
  background: url("https://vip2.loli.net/2022/06/22/XjtnbwFxHI6e3cS.png") no-repeat;
  border: 1px solid #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  z-index: 100;
  cursor: pointer;
}

.chat-panel {
  border: 1px solid #eaeaea;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 101;
  border-radius: 8px;
}

.chat-panel-pc {
  position: absolute;
  right: 15px;
  top: 100px;
  width: 400px;
  height: 600px;
}

.chat-panel-mobile {

}

.chat-panel-msg-container >>> .el-scrollbar__wrap {
  overflow-x: hidden;
}

.msg-container {
  padding: 5px 0;
}

.msg-container-info {
  text-align: center;
}

.msg-container-customer {
  text-align: left;
}

.msg-container-user {
  text-align: right;
}

.msg-item {
  border-radius: 8px;
  color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  max-width: 80%;
  white-space:normal;
  word-break:break-all;
  display:inline-block;
  text-align: left;
}

.msg-item-info {
  background-color: #999999;
  font-size: 12px;
  padding: 2px 10px;
}

.msg-item-customer {
  font-size: 16px;
  padding: 5px 10px;
  background-color: #8896b3;
}

.msg-item-user {
  background-color: #409eff;
  font-size: 16px;
  padding: 5px 10px;
}

</style>
