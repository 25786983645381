<template>
  <app-layout/>
</template>

<script>
import AppLayout from '@/views/layout'

export default {
  components: { AppLayout }
}
</script>

<style>

body {
  margin: 0;
  background-color: #F5F7FA;
}

</style>
