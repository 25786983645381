import { getReq, postReq, putReq } from '@/api/common'

export default {

  /**
     * 获取字典数据
     * @param dictType
     * @returns {Promise<AxiosResponse<any>>}
     */
  getDicts (dictType) {
    return getReq(`/open/system/dict/${dictType}`)
  },

  /**
   * 获取IP归属地
   * @returns {Promise<AxiosResponse<any>>}
   */
  getIpLocation () {
    return getReq('/open/zhyx/ip-info')
  },

  /**
   * 发送验证码
   * @param phone
   * @returns {Promise<AxiosResponse<any>>}
   */
  sendVerificationCode (phone) {
    return postReq(`/open/zhyx/verification/${phone}`)
  },

  /**
   * 登录/注册
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  login (data) {
    return postReq('/open/zhyx/login', data)
  },

  /**
   * 查询收货地址
   * @param phone
   * @returns {Promise<AxiosResponse<any>>}
   */
  getAddressByPhone (phone) {
    return getReq('/zhyx/user/address')
  },

  /**
   * 修改收货地址
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  updateAddress (data) {
    return putReq('/zhyx/user/address', data)
  },

  /**
   * 修改密码
   * @param data
   * @returns {Promise<AxiosResponse<any>>}
   */
  updatePassword (data) {
    return putReq('/zhyx/user/password', data)
  },

  /**
   * 查询用户专业
   * @returns {Promise<AxiosResponse<any>>}
   */
  getApply () {
    return getReq('/zhyx/user/apply')
  },

  /**
   * 查询专业详情
   * @param id
   * @returns {Promise<AxiosResponse<any>>}
   */
  getApplyDetail (id) {
    return getReq('/zhyx/user/apply/id/' + id)
  }

}
