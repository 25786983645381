import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home')
  },
  {
    path: '/apply',
    name: 'apply',
    component: () => import('@/views/apply')
  },
  {
    path: '/school',
    name: 'school',
    component: () => import('@/views/edu/school')
  },
  {
    path: '/school-detail',
    name: 'school-detail',
    component: () => import('@/views/edu/school-detail')
  },
  {
    path: '/major',
    name: 'major',
    component: () => import('@/views/edu/major')
  },
  {
    path: '/major-detail',
    name: 'major-detail',
    component: () => import('@/views/edu/major-detail')
  },
  {
    path: '/registration-condition',
    name: 'registration-condition',
    component: () => import('@/views/edu/registration-conditions')
  },
  {
    path: '/admission-public',
    name: 'admission-public',
    component: () => import('@/views/edu/admission-public')
  },
  {
    path: '/latest-policy',
    name: 'latest-policy',
    component: () => import('@/views/edu/latest-policy')
  },
  {
    path: '/exam-assistant',
    name: 'exam-assistant',
    component: () => import('@/views/edu/exam-assistant')
  },
  {
    path: '/mobile-schedule',
    name: 'mobile-schedule',
    component: () => import('@/views/edu/mobile-schedule-page')
  },
  {
    path: '/international',
    name: 'international',
    component: () => import('@/views/international')
  },
  {
    path: '/international-detail',
    name: 'international-detail',
    component: () => import('@/views/international-detail')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about')
  },
  {
    path: '/my-course',
    name: 'my-course',
    component: () => import('@/views/my-course')
  },
  {
    path: '/user-info',
    name: 'user-info',
    component: () => import('@/views/user-info')
  },
  {
    path: '/my-course-detail',
    name: 'my-course-detail',
    component: () => import('@/views/my-course-detail')
  },
  {
    path: '/*',
    name: 'notfound',
    component: () => import('@/views/notfound')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
