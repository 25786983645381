import Vue from 'vue'
import DataDict from '@/utils/dict'
import openApi from '@/api/open-api'

function install () {
  Vue.use(DataDict, {
    metas: {
      '*': {
        labelField: 'dictLabel',
        valueField: 'dictValue',
        request (dictMeta) {
          return openApi.getDicts(dictMeta.type).then(res => res.data)
        }
      }
    }
  })
}

export default {
  install
}
