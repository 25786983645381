<template>
  <div>
    <div class="nav-bar" @click="linksShow = false">
      <div class="link-icon-container" @click.stop="linksShow = !linksShow">
        <i class="link-icon el-icon-s-unfold"/>
      </div>
      <div class="logo-container" @click="to('/')"></div>
      <user-comp/>
    </div>
    <div class="links-container" v-show="linksShow">
      <div :class="`nav-link${path === '/' ? ' nav-link-selected' : ''}`" @click="to('/')">首页</div>
      <div :class="`nav-link${path === '/school' || path === '/school-detail' || path === '/major' || path === '/major-detail' ? ' nav-link-selected' : ''}`" @click="to('/school')">成人高考</div>
<!--      <div :class="`nav-link${path === '/policy' ? ' nav-link-selected' : ''}`" @click="$eventBus.openChat">政策/咨询</div>-->
<!--      <div :class="`nav-link${path === '/policy' ? ' nav-link-selected' : ''}`" @click="to('/policy')">政策/咨询</div>-->
      <div :class="`nav-link${path === '/international' || path === '/international-detail' ? ' nav-link-selected' : ''}`" @click="to('/international')">国际院校</div>
      <div class="nav-link" @click="openLink('http://edu.yuanzihaoxue.com/')">在线教育</div>
      <div :class="`nav-link${path === '/about' ? ' nav-link-selected' : ''}`" @click="to('/about')">关于我们</div>
    </div>
  </div>

</template>

<script>
import UserComp from '@/components/UserComp/index'

export default {
  name: 'NavCompMobile',
  components: { UserComp },
  data () {
    return {
      linksShow: false
    }
  },
  computed: {
    path () {
      return this.$route.path
    }
  },
  methods: {
    to (path) {
      this.linksShow = false
      if (this.$route.path === path) return
      this.$router.push({ path })
    },
    openLink (path) {
      window.open(path)
    }
  }
}
</script>

<style scoped>

.nav-bar {
  height: 60px;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: space-between;
  width: 100vw;
}

.logo-container {
  background-image: url('https://vip2.loli.net/2022/06/22/bSXH69QCReYIKfa.png');
  background-size: cover;
  height: 40px;
  width: 75px;
  margin: 10px;
  cursor: pointer;
}

.link-icon-container {
  padding: 0 14px;
}

.link-icon-container:hover {
  padding: 0 14px;
  background-color: #f4f4f4;
}

.link-icon {
  color: #333333;
  font-size: 24px;
  line-height: 60px;
}

.links-container {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100px;
  z-index: 99999;
}

.nav-link {
  color: #333333;
  background-color: #f4f4f4;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
  border-top: 1px solid #d3d3d3;
  padding: 0 20px;
}

.nav-link:hover {
  text-decoration: underline;
  background-color: #d3d3d3;
}

.nav-link-selected {
  color: #0060ff;
}

</style>
