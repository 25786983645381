<template>
  <div :class="`footer-container${ $pc ? '' : '-mobile' }`">
    <div>
      <div class="footer-info">公司地址：安徽省蚌埠市凤凰国际B座</div>
      <div class="footer-info">联系电话：0552-3163050, 18961657921（张老师）</div>
      <div class="footer-info">©原子蔚来信息科技（安徽）集团有限公司</div>
      <div class="footer-info icp-info" @click="toGov()">皖ICP备2022004394号-1</div>
    </div>
    <div style="display: flex; margin-top: 12px; justify-content: center;">
      <div>
        <div class="code-container code-1"></div>
        <div class="footer-info">公众号 扫码关注</div>
      </div>
      <div style="margin-left: 12px;">
        <div class="code-container code-2"></div>
        <div class="footer-info">扫码联系 张老师</div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'FooterComp',
  methods: {
    toGov () {
      window.open('https://ahca.miit.gov.cn/')
    }
  }
}
</script>

<style scoped>

.footer-container {
  background-color: #232323;
  display: flex;
  justify-content: space-between;
  padding: 20px 20%;
}

.footer-container-mobile {
  background-color: #232323;
  text-align: center;
  padding: 30px 0;
}

.footer-info {
  font-size: 12px;
  color: #636363;
  line-height: 30px;
}

.icp-info {
  cursor: pointer;
}

.code-container {
  width: 90px;
  height: 90px;
  margin: 0 auto;
}

.code-1 {
  background: url('https://vip2.loli.net/2022/06/22/Ep2PZLVUmYkgBHx.jpg') no-repeat;
  background-size: cover;
}

.code-2 {
  background: url('https://vip2.loli.net/2022/06/22/1g6aptEIW7Gwqko.png') no-repeat;
  background-size: cover;
}

</style>
