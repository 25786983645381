import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui'

// 字典数据组件
import DictData from '@/components/DictData'

import openApi from '@/api/open-api'
import school from '@/api/school/school'

Vue.config.productionTip = false

Vue.use(ElementUI)

DictData.install()

openApi.getIpLocation()
  .then(res => {
    if (res.code === 200) {
      store.commit('setLocation', res.data.location)
    }
  })
  .finally(() => {
    if (!store.state.restrict.location) {
      store.commit('setLocation', 'Anhui')
    }
    school.getRestrictiveCon(store.state.restrict.location).then(res => {
      const restrict = {
        consultFee: res.data?.consultFee || 300,
        enrollFee: res.data?.enrollFee || 300,
        coachFee: res.data?.coachFee || 300,
        admissionUrl: res.data?.admissionUrl || '#'
      }
      store.commit('setRestrict', restrict)
    }).catch(() => {})
  })

if (!store.state.chatId) {
  const guid = () => {
    const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    return (S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4())
  }
  store.commit('setChatId', guid())
}

Vue.prototype.$pc = (() => {
  const userAgentInfo = navigator.userAgent
  const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
  let flag = true
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false
      break
    }
  }
  return flag
})()

// 成功消息
Vue.prototype.msgSuccess = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: 'success'
  })
}

// 警告消息
Vue.prototype.msgWarn = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: 'warning'
  })
}

// 错误消息
Vue.prototype.msgError = function (msg) {
  this.$message({
    showClose: true,
    message: msg.replace('Error: ', ''),
    type: 'error'
  })
}

// 普通消息
Vue.prototype.msgInfo = function (msg) {
  this.$message.info(msg)
}

// 普通消息
Vue.prototype.$eventBus = {}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
