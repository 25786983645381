<template>
  <div class="nav-bar">
    <div class="logo-container" @click="to('/')"></div>
    <div class="links-container">
      <div :class="`nav-link${path === '/' ? ' nav-link-selected' : ''}`" @click="to('/')">首页</div>
      <div :class="`nav-link${path === '/school' || path === '/school-detail' || path === '/major' || path === '/major-detail' || path === '/registration-condition' || path === '/admission-public' || path === '/latest-policy' || path === '/exam-assistant' ? ' nav-link-selected' : ''}`" @click="to('/school')">成人高考</div>
      <div :class="`nav-link${path === '/international' || path === '/international-detail' ? ' nav-link-selected' : ''}`" @click="to('/international')">国际院校</div>
<!--      <div :class="`nav-link${path === '/policy' ? ' nav-link-selected' : ''}`" @click="$eventBus.openChat">政策/咨询</div>-->
<!--      <div :class="`nav-link${path === '/policy' ? ' nav-link-selected' : ''}`" @click="to('/policy')">政策/咨询</div>-->
      <div class="nav-link" @click="openLink('http://edu.yuanzihaoxue.com/')">在线教育</div>
      <div :class="`nav-link${path === '/about' ? ' nav-link-selected' : ''}`" @click="to('/about')">关于我们</div>
    </div>
    <div class="user-container">
      <user-comp/>
    </div>
  </div>
</template>

<script>
import UserComp from '@/components/UserComp/index'
export default {
  name: 'NavCompPc',
  components: { UserComp },
  computed: {
    path () {
      return this.$route.path
    }
  },
  methods: {
    to (path) {
      if (this.$route.path === path) return
      this.$router.push({ path })
    },
    openLink (path) {
      window.open(path)
    }
  }
}
</script>

<style scoped>

.nav-bar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  height: 60px;
  width: 100%;
  min-width: 1000px;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: space-between;
}

.logo-container {
  background-image: url('https://vip2.loli.net/2022/06/22/bSXH69QCReYIKfa.png');
  background-size: cover;
  height: 40px;
  width: 75px;
  cursor: pointer;
  margin: 10px;
}

.links-container {
  width: 75%;
  display: flex;
  justify-content: left;
}

.nav-link {
  color: #333333;
  line-height: 60px;
  cursor: pointer;
  font-size: 16px;
  margin: 0 18px;
  padding: 0 5px;
  user-select: none;
}

.nav-link:hover {
  color: #0060FF;
}

.nav-link-selected {
  border-bottom: 2px solid #0060ff;
}

</style>
