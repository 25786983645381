import axios from 'axios'
import store from '@/store'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const service = axios.create({
  baseURL: '/prod-api',
  timeout: 10000
})

service.interceptors.request.use(
  config => {
    if (!config.url.startsWith('/open/')) {
      config.headers.Authorization = `Bearer ${store.state.auth?.token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (config) => {
    if (config.config.data && !(config.config.data instanceof FormData)) {
      config.requestData = JSON.parse(config.config.data)
    }
    return config.data
  },
  (error) => {
    return Promise.reject(error)
  })

function compileGetParams (params) {
  let _params = `?_=${new Date().getTime()}`
  if (params) {
    const pageMap = new Map()
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key) && params[key] !== null) {
        if (key === 'page' || key === 'size') {
          pageMap.set(key, params[key])
          continue
        }
        _params += `&${key}=${params[key]}`
      }
    }
    if (pageMap.has('page') && pageMap.has('size')) {
      _params += `&limit=${pageMap.get('size')}`
      _params += `&offset=${(pageMap.get('page') - 1) * pageMap.get('size')}`
    }
  }
  return _params
}

export function getReq (url, params, config = {}) {
  return service.get(`${url}${compileGetParams(params)}`, config)
}

export function postReq (url, data, config = {}) {
  return service.post(url, data, config)
}

export function putReq (url, data, config = {}) {
  return service.put(url, data, config)
}

export function deleteReq (url, config = {}) {
  return service.delete(url, config)
}

export function patchReq (url, data, config = {}) {
  return service.patch(url, data, config)
}

/**
 * 文件上传
 * @param url
 * @param data
 * @param config
 * @returns {Promise<AxiosResponse<any>>}
 */
export function uploadReq (url, data, config = {}) {
  config.headers = { 'Content-Type': 'multipart/form-data' }
  config.timeout = 0
  return service.post(url, data, config)
}

/**
 * 带表单的文件上传
 * @param url
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export function postFormDataWithFileReq (url, params) {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: 0
  }
  const data = new FormData()
  for (const k in params) {
    data.append(k, params[k])
  }
  return service.post(url, data, config)
}

/** 以下为 EdgeX 专属 **/

export function postReqByFormData (url, data) {
  return service.post(url, JSON.stringify(data), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
}

export function putReqByFormData (url, data) {
  return service.put(url, JSON.stringify(data), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
}

export default service
