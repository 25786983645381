import Vue from 'vue'
import Vuex from 'vuex'
import persistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    auth: {},
    restrict: {
      location: '',
      consultFee: 300,
      enrollFee: 300,
      coachFee: 300,
      admissionUrl: '#'
    },
    chatId: ''
  },
  getters: {
    isOnline: state => {
      return !!state.auth?.token
    }
  },
  mutations: {
    setAuth (state, auth) {
      state.auth = auth
    },
    setLocation (state, location) {
      state.restrict.location = location
    },
    setRestrict (state, restrict) {
      state.restrict.consultFee = restrict.consultFee || 300
      state.restrict.enrollFee = restrict.enrollFee || 300
      state.restrict.coachFee = restrict.coachFee || 300
      state.restrict.admissionUrl = restrict.admissionUrl || '#'
    },
    setChatId (state, chatId) {
      state.chatId = chatId
    }
  },
  plugins: [persistedState()]
})

export default store
