import { getReq } from '@/api/common'

export default {

  /**
   * 院校列表获取
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  getSchoolList (params) {
    return getReq('/open/zhyx/school/query', params)
  },
  /**
   * 院校详情获取
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  getSchoolDetails (id) {
    return getReq('/open/zhyx/school/detail/' + id)
  },
  /**
   * 院校专业列表获取
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  getMajorListBySchool (id, params) {
    return getReq('/open/zhyx/course/query/school/' + id, params)
  },
  /**
   * 限制条件查询
   */
  getRestrictiveCon (location) {
    return getReq('/open/zhyx/restrict/location/' + location)
  }

}
